// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// @ts-ignore
import {Menu} from '../app/utility';
import {LcmsEnvironment} from "../../app/utility/models/lcms-environment.interface";

const SUPERADMIN = [
  Menu.COMPANY,
  Menu.USER,
  Menu.LESSONS,
  Menu.PACKAGES,
  Menu.LICENSES,
  Menu.CUSTOMIZATION,
  Menu.DOCUMENTS,
  Menu.NEWSLETTER,
  Menu.PARTNER,
  Menu.STATS,
  Menu.LOGS,
  Menu.LESSONPATHS,
  Menu.AUTHORS,
  Menu.PROVIDERS
];
const PORTALADMIN = [
  Menu.COMPANY,
  Menu.USER,
  Menu.LESSONS,
  Menu.PACKAGES,
  Menu.LICENSES,
  Menu.CUSTOMIZATION,
  Menu.DOCUMENTS,
  Menu.NEWSLETTER,
  Menu.PARTNER,
  Menu.STATS,
  Menu.LOGS,
  Menu.LESSONPATHS,
  Menu.AUTHORS,
  Menu.PROVIDERS
];
const FIRMENADMIN = [
  Menu.COMPANY,
  Menu.USER,
  Menu.LESSONS,
  Menu.PACKAGES,
  Menu.LICENSES,
  Menu.CUSTOMIZATION,
  Menu.DOCUMENTS,
  Menu.NEWSLETTER,
  Menu.PARTNER,
  Menu.STATS,
  Menu.LOGS,
  Menu.LESSONPATHS,
  Menu.AUTHORS,
  Menu.PROVIDERS
];


export const environment: LcmsEnvironment = {
  production: false,

  statisticsKey: '',

  server: {
    api: 'https://api-lcms.staging.e5-suite.de/backend',
    frontend: 'http://localhost:9201',
    tenant: 'apotune'
  },

  roles: [
    SUPERADMIN,
    PORTALADMIN,
    FIRMENADMIN
  ],

  difficulties : [
    {
      value : 1,
      label: 'Leicht'
    },
    {
      value : 2,
      label: 'Ausgewogen'
    },
    {
      value : 3,
      label: 'Schwer'
    }
  ],

  classifications : [
    {
      value : 'demo',
      label : 'Basic'
    },
    {
      value : 'basic',
      label: 'Pro',
    },
    {
      value : 'pro',
      label : 'Premium'
    }
  ],
  tenantConfig: {
    lessonDetails: {
      previewVideo: true,
      price: true,
    },
    lessonPathDetails: {
      previewVideo: true,
      price: true,
    },
    authors: {
      enabled: true,
    },
    providers: {
      enabled: true,
    },
    certificate: {
      forLessons: true,
      forLessonPaths: true,
      subline: true,
    }
  },
  lessonLink: ['https://api-lcms.staging.e5-suite.de/wbts/apotune/lesson_', '/index.html'],
  createDefaults: {
    lessonPaths: {}
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
